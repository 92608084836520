import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Project from '../templates/Project';
import Content from '../components/Content';
import WorkDone from '../components/WorkDone'
import projects from '../project_data'
import mastImage from '../images/projects/georgia-tech/mast.jpg'
import SkillCreator from '../components/SkillCreator/SkillCreator';
import ProjectLinks from '../components/ProjectLinks/projectLinks';
import FeatureCreator from '../components/FeatureCreator/FeatureCreator';

const GeorgiaTech = ({ data, path }) => {

    return (
        <Project
            title="Georgia Tech"
            image={mastImage}
            pathname={path}
        >
            <Content header="About">
                <p>
                The Scheller College of Business is the business school 
                at the Georgia Institute of Technology, a public research 
                university in Atlanta, Georgia. The new GT Scheller website 
                was a complete redesign, and this redesign was split between
                 several developers. Apart from implementing new design 
                 changes to the Georgia Tech Scheller website, I also worked 
                 closely with the Hannon hill backend team to provide support 
                 throughout the integration.
                </p>
                <div className="project-page-work">
                    <h2 className="project-page-work--title">Accomplishments</h2>
                        <WorkDone  projectData={projects.get('gt')} />
                </div>
                <div className="project-page-tech">
                    <SkillCreator projectData={projects.get('gt')} />
                </div>
                <div className="project-page-btns">
                    <ProjectLinks projectData={projects.get('gt')} />
                </div>
                <FeatureCreator projectData={projects.get('gt')} />
             </Content>
        </Project>
    )
}


GeorgiaTech.propTypes = {
    // data: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};
  

export default GeorgiaTech